/* eslint-disable no-use-before-define */
/* eslint-disable no-console */

//* *** Lazyloader ***//

const options = {
	rootMargin: '0% 0% 0%',
	threshold: 0,
	debugLogMessages: false,
	lazyBlocks: [],
	assetArray: [],
	assetMap: {},
	isPreview: false,
	lazy: true,
};

function lazyloaderInit() {
	if ( options.debugLogMessages === true ) {
		console.log( 'Lazy Loader initialized!' );
	}
	options.lazyBlocks = [];
	options.assetArray.forEach( ( asset ) => {
		options.assetMap[ asset.assetKey ] = {
			js: () =>
				import( `Assets/js/components/blocks/${ asset.assetKey }` ),
			css: asset.criticalCss !== true && options.isPreview === false,
		};
		options.lazyBlocks.push( `[data-assetkey="${ asset.assetKey }"]` );
	} );

	if ( options.debugLogMessages === true ) {
		console.log( 'Lazyloader Options', options );
	}

	// Check if the browser has intersection observer which is needed for the Lazyloader or if all assets should load immediately anyway.
	if ( options.lazy === false || ! ( 'IntersectionObserver' in window ) ) {
		if ( options.debugLogMessages === true ) {
			console.log( 'running eager loading' );
		}
		try {
			Array.from(
				document
					.querySelectorAll( options.lazyBlocks )
					.forEach( ( block ) => {
						callBlockJs( block );
					} )
			);
		} catch ( error ) {
			console.log( error );
		}
	} else {
		if ( options.debugLogMessages === true ) {
			console.log( 'running normal process' );
		}
		observerInit(
			Array.from( document.querySelectorAll( options.lazyBlocks ) )
		);
	}
}

function observerInit( elementsToObserve = [] ) {
	const observer = new IntersectionObserver( intersectionCallback, options );
	elementsToObserve.forEach( ( element ) => {
		if ( element ) {
			observer.observe( element );
		}
	} );
}

function callBlockJs( block ) {
	options.assetMap[ block.dataset.assetkey ].js().then( ( module ) => {
		try {
			module.default( {
				block,
				css: options.assetMap[ block.dataset.assetkey ].css,
			} );
			block.classList.add( 'loaded' );
		} catch ( error ) {
			console.log( 'could not load block js', error );
		}
	} );
}

// Lazyloader callback function
const intersectionCallback = ( entries, observer ) => {
	if ( options.debugLogMessages === true ) {
		console.log( 'Observer entries', entries );
	}
	entries.forEach( ( entry ) => {
		if ( entry.isIntersecting ) {
			const lazyTarget = entry.target;
			try {
				if ( ! lazyTarget.classList.contains( 'loaded' ) ) {
					callBlockJs( lazyTarget );
				}
			} catch ( error ) {
				console.log(
					error,
					'block data assetkey:',
					lazyTarget.dataset.assetkey,
					' - ',
					'asset import function:',
					options.assetMap[ lazyTarget.dataset.assetkey ],
					'are you missing an asset key or import function?'
				);
			}
			observer.unobserve( lazyTarget );
			if ( options.debugLogMessages === true ) {
				console.log( 'Unobserving lazyElement', lazyTarget );
			}
		}
	} );
};

const api = {
	lazyloaderInit,
	options,
};

export default api;
