// import newsletterController from 'Assets/js/components/newsletter-controller';
import smoothscroll from 'smoothscroll-polyfill';

import jsUtils from 'Assets/js/modules/jsUtils';
import lazyloader from 'Assets/js/modules/cblLazyloader';
import jsAssets from 'Assets/js/modules/jsAssets';
import 'NodeModules/web-animations-js/web-animations.min';

require( 'Assets/js/components/blocks/modal' );
require( 'Assets/js/components/blocks/newsletter' );
require( 'Assets/js/components/blocks/header' );
require( 'Assets/js/components/blocks/buy-now-images' );

window.addEventListener( 'DOMContentLoaded', () => {
	jsUtils.checkDevice();
	lazyloader.options.assetArray = jsAssets.dynamicAssets;
	lazyloader.options.debugLogMessages = false;
	lazyloader.lazyloaderInit();
} );

// kick off the polyfill!
smoothscroll.polyfill();
