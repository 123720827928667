export default function newsletterJs() {
	// Newsletter plugin renders a thank you message when done
	// Need to remove everything surrounding the thank you message
	// as per design.

	const DELAY = 100;

	const submitButton = document.querySelector(
		'.pr-cdb-form-control__submit'
	);
	const formInputs = document.querySelectorAll( '.pr-cdb-form-control' );
	const modal = document.querySelector( '.modal' );
	let hasAnimated = false;

	const isPartiallyVisible = ( elem ) => {
		if ( elem ) {
			const position = elem.getBoundingClientRect();

			if ( position.top < window.innerHeight && position.bottom >= 0 ) {
				return true;
			}
		}

		return false;
	};

	const animateNewsletter = () => {
		const description = document.querySelector(
			'.newsletter-cta__description'
		);
		const button = document.querySelector( '.newsletter-cta__button' );
		const newsletterContainer = document.querySelector( '.newsletter-cta' );

		if ( isPartiallyVisible( newsletterContainer ) && ! hasAnimated ) {
			setTimeout( () => {
				description.classList.add( 'fadeInUp' );
			}, DELAY );

			setTimeout( () => {
				button.classList.add( 'fadeInUp' );
			}, DELAY * 2 );

			hasAnimated = true;
		}
	};
	if ( submitButton ) {
		submitButton.classList.add( 'disabled' );
	}

	const closeModal = () => {
		modal.classList.add( 'fadeOut' );
		modal.parentElement.classList.remove( 'modal-active' );
		setTimeout( () => {
			modal.classList.remove( 'modal--visible' );
			modal.classList.remove( 'fadeIn' );
		}, 500 );
	};

	// validate form and turn submit form green if valid
	const validateForm = () => {
		const inputArray = Array.prototype.slice.call(
			document.querySelectorAll( '.pr-cdb-form-control' )
		);
		if ( inputArray.some( ( input ) => ! input.checkValidity() ) ) {
			submitButton.classList.add( 'disabled' );
			return false;
		}

		submitButton.classList.remove( 'disabled' );
		return true;
	};

	const clearNewsletterContent = () => {
		const isValid = validateForm();

		if ( isValid ) {
			const submitSuccess = setInterval( () => {
				const successDiv = document.querySelector(
					'.pr-cdb-thank-you'
				);
				if ( successDiv ) {
					clearInterval( submitSuccess );
					submitButton.removeEventListener( 'click', submitButton );

					document
						.querySelector(
							'.newsletter__content > .newsletter__title'
						)
						.remove();
					document
						.querySelector(
							'.newsletter__content > .newsletter__body'
						)
						.remove();
					document
						.querySelector(
							'.newsletter__content > .newsletter__footer'
						)
						.remove();
					document
						.querySelector( '.newsletter__close button' )
						.addEventListener( 'click', closeModal );
				}
			}, 1 );
		}
	};

	formInputs.forEach( ( input ) => {
		const thisInput = input;

		thisInput.addEventListener( 'change', validateForm );
	} );
	if ( submitButton ) {
		submitButton.addEventListener( 'click', clearNewsletterContent );
	}
	window.addEventListener( 'scroll', animateNewsletter );
}

newsletterJs();
