window.addEventListener( 'DOMContentLoaded', () => {
	// Mobile navigation listeners
	const button = document.querySelector( '.header__nav-button' );
	const container = document.querySelector( '.header__container' );
	const body = document.querySelector( 'body' );

	button.addEventListener( 'click', () => {
		button.classList.toggle( 'active' );
		container.classList.toggle( 'active' );
		body.classList.toggle( 'modal-active' );
	} );
} );
