function addImageHoverListeners(
	imageElements,
	orientation = 'left',
	language = 'en'
) {
	if ( imageElements ) {
		let hoverLink = '';
		let defaultLink = '';
		switch ( orientation ) {
			case 'bottle':
				hoverLink =
					'/wp-content/themes/global-theme/assets/images/animations/bottle/flashing-neon-bottle-hover-state.webp';
				defaultLink =
					'/wp-content/themes/global-theme/assets/images/animations/bottle/flashing-neon-bottle-hover-state.webp';
				break;
			case 'right':
				hoverLink = `/wp-content/themes/global-theme/assets/images/animations/buy-now-${ orientation }/flashing-buy-now-${ orientation }-arrow-${ language }-hover-state.webp`;
				defaultLink = `/wp-content/themes/global-theme/assets/images/animations/buy-now-${ orientation }/flashing-buy-now-${ orientation }-arrow-${ language }.webp`;
				break;
			case 'left':
				hoverLink = `/wp-content/themes/global-theme/assets/images/animations/buy-now-${ orientation }/flashing-buy-now-${ orientation }-arrow-${ language }-hover-state.webp`;
				defaultLink = `/wp-content/themes/global-theme/assets/images/animations/buy-now-${ orientation }/flashing-buy-now-${ orientation }-arrow-${ language }.webp`;
				break;
			case 'round':
				hoverLink = `/wp-content/themes/global-theme/assets/images/animations/buy-now/buy-now-${ language }-hover.webp`;
				defaultLink = `/wp-content/themes/global-theme/assets/images/animations/buy-now/flashing-buy-now-${ language }.webp`;
				break;
			default:
				break;
		}
		imageElements.forEach( ( image ) => {
			image.addEventListener( 'mouseover', () => {
				image
					.querySelector( 'source' )
					.setAttribute( 'srcset', hoverLink );
			} );
			image.addEventListener( 'mouseleave', () => {
				image
					.querySelector( 'source' )
					.setAttribute( 'srcset', defaultLink );
			} );
		} );
	}
}

export default function activateBuyNowListeners() {
	const buyNowLeftImages = document.querySelectorAll( '.buy-now-left' );
	const buyNowRightImages = document.querySelectorAll( '.buy-now-right' );
	const buyNowRoundImages = document.querySelectorAll( '.buy-now-round' );
	const buyNowBottles = document.querySelectorAll( '.drink__bottle' );

	const language = document.documentElement.lang.substring( 0, 2 );

	addImageHoverListeners( buyNowLeftImages, 'left', language );
	addImageHoverListeners( buyNowRightImages, 'right', language );
	addImageHoverListeners( buyNowRoundImages, 'round', language );
	addImageHoverListeners( buyNowBottles, 'bottle' );
}
activateBuyNowListeners();
