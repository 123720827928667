/* eslint-disable consistent-return */
/* eslint-disable no-console */
function getPosition( element ) {
	const scrollElementTag = document.scrollingElement.tagName;
	let xPosition = 0;
	let yPosition = 0;

	while ( element ) {
		xPosition +=
			element.offsetLeft - element.scrollLeft + element.clientLeft;
		yPosition += element.offsetTop - element.scrollTop + element.clientTop;
		element = element.offsetParent;
	}
	// Quirks mode safety - in case of missing DOCTYPE
	if ( scrollElementTag === 'BODY' ) {
		yPosition += document.querySelector( 'body' ).scrollTop;
	}
	return { x: xPosition, y: yPosition };
}

function toggleClassByValidity( input, button ) {
	input.addEventListener( 'input', () => {
		if ( input.validity.valid && input.value ) {
			if ( ! button.classList.contains( 'valid' ) ) {
				button.classList.add( 'valid' );
			}
			if ( ! input.classList.contains( 'valid' ) ) {
				input.classList.add( 'valid' );
			}
		} else {
			button.classList.remove( 'valid' );
			input.classList.remove( 'valid' );
		}
	} );
}

function checkDevice() {
	const deviceAgent = navigator.userAgent.toLowerCase();
	const htmlElement = document.querySelector( 'html' );
	if ( navigator.connection ) {
		htmlElement.classList.add( navigator.connection.effectiveType );
	}
	if ( deviceAgent.match( /(iphone|ipod|ipad)/ ) ) {
		htmlElement.classList.add( 'ios' );
		htmlElement.classList.add( 'mobile' );
	}
	if ( navigator.userAgent.search( 'MSIE' ) >= 0 ) {
		htmlElement.classList.add( 'ie' );
	} else if ( navigator.userAgent.search( 'Edge' ) >= 0 ) {
		htmlElement.classList.add( 'edge-legacy' );
	} else if ( navigator.userAgent.search( 'Chrome' ) >= 0 ) {
		htmlElement.classList.add( 'chrome' );
	} else if ( navigator.userAgent.search( 'Chrome' ) >= 0 ) {
		htmlElement.classList.add( 'chrome' );
	} else if ( navigator.userAgent.search( 'Firefox' ) >= 0 ) {
		htmlElement.classList.add( 'firefox' );
	} else if (
		navigator.userAgent.search( 'Safari' ) >= 0 &&
		navigator.userAgent.search( 'Chrome' ) < 0
	) {
		htmlElement.classList.add( 'safari' );
	} else if ( navigator.userAgent.search( 'Opera' ) >= 0 ) {
		htmlElement.classList.add( 'opera' );
	}
}

async function waitForLoad( img, func ) {
	const loaded = new Promise( ( resolve ) => {
		if ( img.complete ) {
			if ( typeof func === 'function' ) {
				func();
			}
			return resolve( true );
		}
		img.addEventListener(
			'load',
			() => {
				if ( typeof func === 'function' ) {
					func();
				}
				return resolve( true );
			},
			{ once: true }
		);
	} ).catch( ( error ) =>
		console.log( error, 'could not load the image', img )
	);
	return loaded;
}

async function supportsWebp() {
	// eslint-disable-next-line no-restricted-globals
	if ( ! self.createImageBitmap ) return false;

	const webpData =
		'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
	const blob = await fetch( webpData ).then( ( r ) => r.blob() );
	return createImageBitmap( blob ).then(
		() => true,
		() => false
	);
}
// ( async () => {
// 	if ( await supportsWebp() ) {
// 		return true;
// 	}
// 	return false;
// } )();

function footerIntersection( entries, element ) {
	entries.forEach( ( entry ) => {
		if ( element ) {
			if ( entry.isIntersecting ) {
				const { height } = entry.boundingClientRect;
				element.style.bottom = `${ height }px`;
			} else {
				element.style.bottom = '0px';
			}
		}
	} );
}

function getSwiperAssets() {
	const getSwiperJs = () =>
		import(
			/* webpackChunkName: 'swiper.js.lazy-chunk' */ 'NodeModules/swiper/js/swiper.js'
		);

	const getSwiperCss = () =>
		import(
			/* webpackChunkName: 'swiper.css.lazy-chunk' */ 'NodeModules/swiper/css/swiper.css'
		);
	getSwiperCss().then( ( result ) => {
		const swipeStyle = document.createElement( 'style' );
		swipeStyle.innerHTML = `${ result.default[ 0 ][ 1 ] }`;
		document.querySelector( 'head' ).appendChild( swipeStyle );
	} );

	const promisedJs = Promise.all( [ getSwiperJs() ] ).then( ( values ) => {
		return values;
	} );
	return promisedJs;
}

function getJquery() {
	return () =>
		import(
			/* webpackChunkName: 'jquery.lazy-chunk' */ 'NodeModules/jquery/dist/jquery.min.js'
		);
}

function getGsap() {
	const gsapCore = () =>
		import( 'NodeModules/gsap/dist/gsap.min.js' ).then( ( gsapObj ) => {
			const { gsap } = gsapObj;
			return gsap;
		} );
	const gsapPlugin = () =>
		import( 'NodeModules/gsap/dist/ScrollTrigger.min.js' ).then(
			( scrollObj ) => {
				const ScrollTrigger = scrollObj;
				return ScrollTrigger;
			}
		);
	const prom = Promise.all( [ gsapCore(), gsapPlugin() ] ).then(
		( values ) => {
			return values;
		}
	);
	return prom;
}
function inCriticalCssConfig( assetKey ) {
	if (
		globalThis.criticalConfig &&
		globalThis.criticalConfig.indexOf( assetKey ) === -1
	) {
		return false;
	}
	return true;
}
function loadCss( assetKey, options = { css: true } ) {
	const promise = new Promise( ( resolve ) => {
		if ( options.css === true && ! inCriticalCssConfig( assetKey ) ) {
			import(
				/* webpackChunkName: "[request]" */ `Assets/scss/components/blocks/${ assetKey }.scss`
			).then( () => resolve( true ) );
		} else {
			return resolve( true );
		}
	} );
	return promise;
}

const api = {
	loadCss,
	waitForLoad,
	checkDevice,
	toggleClassByValidity,
	getPosition,
	footerIntersection,
	getSwiperAssets,
	getGsap,
	getJquery,
	supportsWebp,
};
export default api;
