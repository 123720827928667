export default function modalJs() {
	// Close modal when a close button in the modal has been pressed
	const modals = document.querySelectorAll( '.modal' );
	const modalTriggers = document.querySelectorAll( '.modal__trigger' );
	const modalCloseButtons = document.querySelectorAll( '.modal__close' );

	// Move all modals to bottom of body so that position
	// of parent doesn't become an issue
	modals.forEach( ( modal ) => {
		document.body.appendChild( modal );
	} );

	modalTriggers.forEach( ( trigger ) => {
		trigger.addEventListener( 'click', ( event ) => {
			event.preventDefault();
			const { modalName } = trigger.dataset;
			const thisModal = document.querySelector(
				`.modal[data-modal-name="${ modalName }"]`
			);
			thisModal.classList.add( 'modal--visible' );
			thisModal.classList.add( 'fadeIn' );
			thisModal.classList.remove( 'fadeOut' );
			thisModal.parentElement.classList.add( 'modal-active' );
			document.body.classList.add( 'modal-active' );
		} );
	} );

	modalCloseButtons.forEach( ( closeButton ) => {
		closeButton.addEventListener( 'click', ( event ) => {
			event.preventDefault();
			// remove instead of toggle because it might activate inactive modals on the page
			const activeModal = document.querySelector( '.modal--visible' );
			activeModal.classList.add( 'fadeOut' );
			document.body.classList.remove( 'modal-active' );
			setTimeout( () => {
				activeModal.classList.remove( 'modal--visible' );
				activeModal.classList.remove( 'fadeIn' );
				activeModal.classList.remove( 'fadeOut' );
			}, 500 );
		} );
	} );

	const resizeActiveModal = () => {
		modals.forEach( ( modal ) => {
			const thisModal = modal;
			thisModal.style.height = `${ window.innerHeight }px`;
		} );
	};

	resizeActiveModal();

	window.addEventListener( 'resize', resizeActiveModal );
}

modalJs();
